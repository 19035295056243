import * as React from "react"
import { graphql } from "gatsby"
import * as cheerio from 'cheerio'

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import JsonLd from "@components/common/JsonLd"
import Top from "@components/blog/Top"
import Main from "@components/blog/Main"
import Column from "@components/blog/Column"

const Blog = ({ pageContext, data }) => {
  const latest_blogs = data.allMicrocmsBlog.edges.map(elem => ({...elem.node, id: elem.node.blogId})).filter(elem => elem.blogId !== pageContext.blogId).slice(0, 3);
  const $ = cheerio.load(pageContext.summary, { ignoreWhitespace: true });
  const description = pageContext.meta_description?
    pageContext.meta_description:
    $.text();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const currentCampaignName = searchParams.get('utm_campaign');
    if(currentCampaignName === null) return false
    const tags = document.getElementsByTagName('a');
    for(let i = 0; i < tags.length; i = i + 1) {
      const targetTag = tags[i]
      if(targetTag.href.includes('https://www.cuddle-jp.com/')) {
        targetTag.setAttribute('href', `${targetTag.href}&utm_content=${currentCampaignName}`);
      }
    }
  },[])
  return (
    <Layout>
      <Seo
        title={`${pageContext.title} | マッチングアプリ アナライザー`}
        description={description}
        image={pageContext.hero_image.url}
        article={true}
      />
      <JsonLd
        type='blog'
        title={`${pageContext.title} | マッチングアプリ アナライザー`}
        description={description}
        detail={pageContext}
      />
      <Top hero_image={pageContext.hero_image} />
      <Main {...pageContext}/>
      <Column related_blogs={pageContext.related_blogs.length > 0?
        pageContext.related_blogs:
        latest_blogs
      }/>
    </Layout>
  )
}

export const query = graphql`
  {
    allMicrocmsBlog(
      sort: { fields: [publishedAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          summary
          meta_description
          content
          category
          publishedAt
          revisedAt
        }
      }
    }
  }
`

export default Blog
