import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

import DefaultImage from "@images/OG.png"

const JsonLd = ({
  type,
  title,
  description,
  detail
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    siteUrl,
  } = site.siteMetadata;

  let jsonLdStructuredData;
  switch(type) {
    case 'blog':
      jsonLdStructuredData = [
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "TOP",
              "item": "https://match-analyze.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": title,
              "item": `${siteUrl}${pathname}`
            }
          ]
        },
        {
          "@context": "https://schema.org/",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": `${siteUrl}/blog/`,
          },
          author: {
            "@type": "Person",
            name: "マッチングアプリ アナライザー編集長",
            image: "https://match-analyze.com/images/icon.png",
          },
          publisher: {
            "@type": "Organization",
            name: "マッチングアプリ アナライザー",
            url: siteUrl,
            logo: {
                "@type": "ImageObject",
                url: "https://match-analyze.com/images/icon.png",
                width: 300,
                height: 300
            }
          },
          name: title,
          headline: title,
          image: detail.hero_image ? detail.hero_image.url : DefaultImage,
          url: `${siteUrl}${pathname}`,
          description: description,
          datePublished: detail.publishedAt,
          dateCreated: detail.publishedAt,
          dateModified: detail.revisedAt,
        }
      ]
      break;
      default:
        jsonLdStructuredData = {
          "@context": "https://schema.org",
          "@type": "Organization",
          "logo": "https://match-analyze.com/images/icon.png",
          "name": "マッチングアプリ アナライザー",
          "url": "https://match-analyze.com/",
          "description": "元マッチングアプリ開発者が徹底解説",
        }
      break;
  }

  return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLdStructuredData)}</script>
      </Helmet>
  );
};

export default JsonLd;

const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        siteUrl: siteUrl
      }
    }
  }
`